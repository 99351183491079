// import { lazy } from "preact/compat";
// import { WaitComponent } from "../Aysnc/Async";
import Main from "../Main/Main";
import Redirect from "../Redirect/Redirect"; // const Main = lazy(() => "../Main/Main");
// const Redirect = lazy(() => "../Redirect/Redirect");

var App = function App() {
  var url = new URL(window.location);

  if (url.pathname.match(/\/redirect\/.+/)) {
    return h(Redirect, null); // return <WaitComponent Component={Redirect} />;
  }

  if (url.pathname.match(/\/redirect$/)) {
    return window.location.replace("https://short.wasc.me");
  } //   return <WaitComponent Component={Main} />;


  return h(Main, null);
};

export default App;