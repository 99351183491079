function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useEffect, useState } from "preact/compat";
import init, { get_key, decrypt } from "../../../wasm/pkg/index";

var Redirect = function Redirect() {
  var _useState = useState(""),
      _useState2 = _slicedToArray(_useState, 2),
      message = _useState2[0],
      setMessage = _useState2[1];

  useEffect(function () {
    function run() {
      return _run.apply(this, arguments);
    }

    function _run() {
      _run = _asyncToGenerator(function* () {
        var url = new URL(window.location);
        yield init();
        var urlSegments = window.decodeURI(url.pathname).split("/");
        var slug = urlSegments[urlSegments.length - 1];
        var key = get_key(slug);

        var _yield$fetch$then = yield fetch("https://apis.short.wasc.me/".concat(key)).then(function (response) {
          return response.json();
        }),
            value = _yield$fetch$then.value;

        if (!value) {
          return setMessage("No link found for s.wasc.me/".concat(slug, "."));
        }

        var _JSON$parse = JSON.parse(value),
            payload = _JSON$parse.payload,
            salt = _JSON$parse.salt;

        var link = decrypt(slug, payload, salt); // TODO Is this a good idea?

        var redirectTarget = link.match(/https?/) ? link : "https://".concat(link);
        setMessage("Redirecting to: ".concat(redirectTarget, "..."));
        return window.location.replace(redirectTarget);
      });
      return _run.apply(this, arguments);
    }

    run();
  }, [init]);
  return h("span", {
    class: "text-lg py-4 px-2"
  }, message);
};

export default Redirect;